@font-face {
  font-family: 'Old Standard TT';
  src: url('fonts/Old_Standard_TT/OldStandardTT-Regular.ttf');
}

body {
  margin: 0;
  background-color: #41b3a3
}

